'use client';

import React, { useEffect, useState } from 'react';

// Slider imports
import Image from 'next/image';

import { useSearchParams, useRouter } from 'next/navigation';

import Autoplay from 'embla-carousel-autoplay';

import Fade from 'embla-carousel-fade';

// import { getBannerHomePage } from '@/sanity/sanity-utils';
import urlFor from '@/sanity/urlFor';

import { userUIStore } from '@/stores/userUIStore';

// import { Button } from '@/components/ui/button';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from '@/components/ui/carousel';

const BannerCarousel = ({ isSession }: { isSession: boolean }) => {
  const { toggleDepositSheet, toggleDepositModal, toggleLoginModal } = userUIStore();
  const [banners, setBanners] = useState<any[]>([]);
  const searchParams = useSearchParams();
  const router = useRouter();

  const action = searchParams.get('action');

  useEffect(() => {
    async function fetchData() {
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

      const data = await fetch('/api/banners?type=home', {
        method: 'GET',
        cache: 'no-store',
        next: { revalidate: 0 },
      });

      if (!data.ok) {
        throw new Error('Failed to fetch banners');
      }

      const response = await data.json();
      const filteredBanners = response.banners.filter((banner: { app: string; isMobile?: boolean }) => {
        if (banner.app !== 'hondubet') return false;
        if (isMobile) return banner.isMobile;
        return !banner.isMobile;
      });
      const sortedBanners = filteredBanners.sort((a: { orderRank: string }, b: { orderRank: any }) => {
        return a.orderRank.localeCompare(b.orderRank);
      });
      setBanners(sortedBanners);
    }

    fetchData();
  }, []);

  // const handleDepositModal = () => {
  //   if (window.innerWidth > 768) {
  //     toggleDepositModal();
  //   } else {
  //     toggleDepositSheet();
  //   }
  // };

  useEffect(() => {
    const clearUrlParams = () => {
      const url = new URL(window.location.href);
      url.search = '';
      window.history.replaceState(null, '', url.toString());
    };

    if (action === 'login' && !isSession) {
      toggleLoginModal();
      clearUrlParams();
    }
    if (action === 'deposit' && isSession) {
      // handleDepositModal();
      clearUrlParams();
    }
  }, [action, isSession, toggleLoginModal, toggleDepositModal, toggleDepositSheet]);

  return (
    <div className="relative w-full">
      <Carousel
        className="h-full w-full max-lg:w-[100vw] max-lg:-ml-4 rounded-lg"
        plugins={[Fade(), Autoplay({ delay: 5000 })]}
        opts={{
          align: 'start', // Align slides to the start
        }}
      >
        <CarouselContent className="w-full max-lg:m-0 max-lg:p-0">
          {banners.map((banner) => {
            const imageUrl = urlFor(banner.imageUrl).url();
            return (
              // eslint-disable-next-line no-underscore-dangle
              <CarouselItem key={banner._id} className="w-full  max-lg:m-0 max-lg:p-0">
                <div className="relative h-auto w-full rounded-lg overflow-hidden">
                  <div className="relative w-full h-full">
                    <Image
                      src={imageUrl}
                      alt="banner image"
                      width="0"
                      height="0"
                      sizes="100vw"
                      className="h-auto w-full"
                      onClick={() => {
                        router.push(banner.urlRedirection);
                      }}
                      priority
                    />
                  </div>
                </div>
                {/* {banner.title && (
                    <div className="absolute z-10 top-0 p-4 sm:p-6 md:p-8 flex flex-col justify-center items-start h-full text-center">
                      <h1 className="text-lg max-sm:text-lg md:text-2xl lg:text-4xl font-bold text-sivarbet-text">
                        {banner.title}
                      </h1>
                      <span className="text-sm max-sm:text-sm md:text-lg font-normal text-sivarbet-secondary-text mt-2">
                        Description goes here
                      </span>
                      {isSession ? (
                        <Button className="btn-banner" onClick={handleDepositModal}>
                          Deposita Ya!
                        </Button>
                      ) : (
                        <Button className="btn-banner" onClick={toggleRegisterModal}>
                          Regístrate
                        </Button>
                      )}
                    </div>
                  )} */}
              </CarouselItem>
            );
          })}
        </CarouselContent>
      </Carousel>
    </div>
  );
};

export default BannerCarousel;
