'use client';

import React, {
  useEffect, useState,
} from 'react';

import Image from 'next/image';
import Link from 'next/link';

// import { useRouter } from 'next/navigation';

import { useRouter } from 'next/navigation';

import { useSession } from 'next-auth/react';
import { HiOutlineCurrencyDollar } from 'react-icons/hi2';

import { IoHeartOutline, IoHeartSharp } from 'react-icons/io5';

import { useAuthStore } from '@/stores/userStore';
import { userUIStore } from '@/stores/userUIStore';

import AcceptModal from '../modals/AcceptModal';
import RegistrationPopup from '../modals/RegistrationPopup';
import { Button } from '../ui/button';
import { Card, CardContent } from '../ui/card';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '../ui/carousel';
import {
  Tooltip, TooltipContent, TooltipProvider, TooltipTrigger,
} from '../ui/tooltip';

// Definición de interfaces para el componente
interface GameItem {
  identifier: string;
  provider: string;
  title: string;
  producer: string;
  usd_only: boolean;
}

interface CarouselData {
  url: string;
  headline: string;
  icon: React.ReactNode;
  items: GameItem[];
}

interface CarouselGamesProps {
  data: CarouselData[];
}

const CarouselGames: React.FC<CarouselGamesProps> = ({ data }) => {
  const [favorites, setFavorites] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isAcceptModalUrl, setIsAcceptModalUrl] = useState<string>('');
  const { data: session } = useSession();
  const { account, balance } = useAuthStore();

  const {
    toggleLoginModal, toggleDepositModal, toggleDepositSheet, toggleAcceptModal,
  } = userUIStore();
  const router = useRouter();

  useEffect(() => {
    const savedFavorites = localStorage?.getItem('favorites');
    if (savedFavorites) {
      setFavorites(JSON.parse(savedFavorites));
    }
  }, []);

  const toggleFavorite = (gameCode: string) => {
    if (!session) {
      toggleLoginModal();
    } else {
      setFavorites((prev) => {
        const isAlreadyFavorited = prev.includes(gameCode);
        let newFavorites;

        if (isAlreadyFavorited) {
          newFavorites = prev.filter((code) => { return code !== gameCode; });
        } else {
          newFavorites = [...prev, gameCode];
        // setShowToast(true);
        }

        localStorage?.setItem('favorites', JSON.stringify(newFavorites));

        return newFavorites;
      });
    }
  };

  const isFavorite = (gameCode: string) => { return favorites.includes(gameCode); };

  return (
    <div>
      {data.map((carousel) => {
        return (
          <div className="w-full flex flex-wrap overflow-hidden" key={carousel.headline}>
            <div className="w-full pb-20 max-lg:pb-2 max-md:pb-2 max-sm:pb-2">
              <Carousel
                className="mb-1 h-44 w-full"
                opts={{
                  align: 'start',
                }}
              >
                <div className="flex items-center justify-between mt-5 pb-3 max-lg:mt-1 max-md:mt-1 max-sm:mt-1 max-lg:pb-1 max-md:pb-1 max-sm:pb-1">
                  <div className="flex items-center gap-2 text-lg font-medium text-sivarbet-text">
                    {carousel.icon}
                    <Link href={carousel.url} passHref>
                      <h2 className="w-1/2 text-base sm:text-sm md:text-lg lg:text-xl font-medium">{carousel.headline}</h2>
                    </Link>
                  </div>
                  <div className="flex flex-row-reverse gap-3">
                    <CarouselNext className="static rounded-lg border-sivarbet-border flex items-center bg-transparent transform-none" />
                    <CarouselPrevious className="static rounded-lg border-sivarbet-border flex items-center bg-transparent transform-none" />
                  </div>
                </div>
                <CarouselContent className="flex h-full">
                  {carousel?.items?.map((item) => {
                    return (
                      <CarouselItem
                        className="w-full max-sm:basis-1/2 md:basis-1/2 lg:basis-1/4 xl:basis-1/4 min-[1537px]:basis-1/6 h-full flex justify-center rounded-lg"
                        key={item.identifier}
                      >
                        <Card className="bg-transparent max-sm:w-96 xl:w-[300px] lg:w-full p-0 w-full border-none flex justify-center items-center h-full rounded-lg">
                          <CardContent className="w-full text-sivarbet-text p-0 flex flex-col justify-center items-center rounded-lg ">
                            <div className="w-full flex items-center justify-center relative group hover:opacity-75">
                              <Image
                                className="w-full  rounded-xl h-full xl:h-[150px] xl:w-full max-lg:h-36 max-md:h-full cursor-pointer transition duration-300 ease-in-out "
                                src={`https://cdn.softswiss.net/i/s2/${item.provider}/${item.identifier.split(':')[1]}.png`}
                                alt={item.title}
                                width={200}
                                height={0}
                              />
                              <Button
                                type="button"
                                className="hidden absolute hover:text-black bg-[radial-gradient(circle,_rgb(149,251,63)_100%,_rgb(70,252,109)_100%)] text-white group-hover:block font-semibold group  !opacity-200"
                                onClick={() => {
                                  if (!session) {
                                    toggleLoginModal();
                                  } else if (session && !account.fullProfile) {
                                    setIsModalOpen(true);
                                  } else if (!balance[0].amount || balance[0].amount === 0) {
                                    if (window.innerWidth < 1000) {
                                      toggleDepositSheet();
                                    } else {
                                      toggleDepositModal();
                                    }
                                  } else if (item.usd_only) {
                                    toggleAcceptModal();
                                    setIsAcceptModalUrl(`/casino/play?provider=${item.provider}&gamecode=${item.identifier}&currency=${item.usd_only ? 'USD' : 'HNL'}`);
                                  } else router.push(`/casino/play?provider=${item.provider}&gamecode=${item.identifier}&currency=${item.usd_only ? 'USD' : 'HNL'}`);
                                }}
                              >
                                Jugar
                              </Button>
                            </div>
                            <div className="w-full flex justify-between items-center pt-1">
                              <p
                                className="text-base max-lg:text-sm font-medium text-sivarbet-text overflow-hidden whitespace-nowrap text-ellipsis w-3/4 max-w-[200px] max-lg:max-w-[120px] max-md:max-w-[110px] max-sm:max-w-[100px]"
                                title={item.title}
                              >
                                {item.title}
                              </p>
                              <div className="flex items-center">
                                {item.usd_only && (
                                  <TooltipProvider>
                                    <Tooltip>
                                      <TooltipTrigger asChild>
                                        <Button className="bg-transparent h-0 p-0 hover:bg-transparent">
                                          <HiOutlineCurrencyDollar className="text-xl text-sivarbet-secondary" />
                                        </Button>
                                      </TooltipTrigger>
                                      <TooltipContent className="bg-sivarbet-secondary-bg text-sivarbet-primary border-sivarbet-border">
                                        <p>Este juego es solo en USD (1 USD = 25 HNL)</p>
                                      </TooltipContent>
                                    </Tooltip>
                                  </TooltipProvider>
                                ) }
                                <Button
                                  type="button"
                                  className="bg-transparent h-0 hover:bg-transparent"
                                  onClick={() => { return toggleFavorite(item.identifier); }}
                                >
                                  {isFavorite(item.identifier) ? (
                                    <IoHeartSharp className="text-red-600 text-xl" />
                                  ) : (
                                    <IoHeartOutline className="text-xl" />
                                  )}
                                </Button>
                              </div>
                            </div>
                            <div className="w-full text-start">
                              <span className="text-sm max-lg:text-xs text-sivarbet-secondary">
                                {item.producer}
                              </span>
                            </div>
                          </CardContent>
                        </Card>
                      </CarouselItem>
                    );
                  })}
                </CarouselContent>
              </Carousel>
            </div>
          </div>
        );
      })}
      {isModalOpen && <RegistrationPopup isOpen isGame />}
      <AcceptModal url={isAcceptModalUrl} />
    </div>
  );
};

export default CarouselGames;
