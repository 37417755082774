'use client';

import React, {
  useEffect,
  useState,
  useRef,
  MutableRefObject,
  useReducer,
  useCallback,
  memo,
} from 'react';

import Link from 'next/link';

import { useSearchParams } from 'next/navigation';
import Script from 'next/script';

import { useSession } from 'next-auth/react';

import { FaRankingStar } from 'react-icons/fa6';
import { GiChart } from 'react-icons/gi';

import { IoHeart } from 'react-icons/io5';
import { MdLiveTv, MdRecommend } from 'react-icons/md';
import { PiShootingStarLight } from 'react-icons/pi';

import { CategoriesGames } from '@/features/casino/utils';
import { fetchConfirmationPayphone } from '@/features/deposit/services';

import { getDatGames } from '@/utils/core';

// import {
//   popularGames,
//   crashGames,
//   newGames,
//   recommendedGames,
//   liveGames,
// } from '@/utils/games';

import { AUTHENTICATED_SESSION } from '@/constants/core';

import BannerCarousel from '../banner/BannerCarousel';
import LeagueSlider from '../banner/LeagueSlider';
// import SWSmoothCarousel from '../banner/SWSmoothCarousel';
import SWSmoothCarousel from '../banner/SWSmoothCarousel';
import LoadingSpinner from '../common/LoadingSpinner';
import EmailVerificationModal from '../modals/EmailVerificationModal';
import { useToast } from '../ui/use-toast';

interface GameItem {
  identifier: string;
  provider: string;
  title: string;
  producer: string;
  usd_only: boolean;
}

interface CarouselData {
  url: string;
  headline: string;
  icon: React.ReactNode;
  items: GameItem[];
}

const HomeTab = () => {
  interface State {
    categories: CarouselData[];
    isFavoritesLoaded: boolean;
    isLoading: boolean;
  }
  const initialState: State = {
    categories: [],
    isFavoritesLoaded: false,
    isLoading: false,
  };

  type Action =
    | { type: 'SET_CATEGORIES'; payload: CarouselData[] }
    | { type: 'SET_LOADING'; payload: boolean }
    | { type: 'SET_FAVORITES_LOADED'; payload: boolean };

  function reducer(state: State, action: Action): State {
    switch (action.type) {
      case 'SET_CATEGORIES':
        return { ...state, categories: action.payload };
      case 'SET_LOADING':
        return { ...state, isLoading: action.payload };
      case 'SET_FAVORITES_LOADED':
        return { ...state, isFavoritesLoaded: action.payload };
      default:
        return state;
    }
  }

  const IconCategory: Record<string, React.ReactNode> = {
    recomendados: <MdRecommend />,
    nuevos: <PiShootingStarLight />,
    live: <MdLiveTv />,
    crash: <GiChart />,
    popular: <FaRankingStar />,
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const [isScriptLoaded, setIsScriptLoaded] = useState<boolean>(false);
  const [isxtremepushSet, setXtremepush] = useState(false);
  const widgetRef = useRef<MutableRefObject<any>>(null);
  const { toast } = useToast();
  const scriptUrl = process.env.NEXT_PUBLIC_SCRIPT_URL as string;
  const integration = process.env.NEXT_PUBLIC_ALTENAR_INTEGRATION as string;
  const { data: session, status } = useSession();
  // Load all games
  const loadData = useCallback(async () => {
    dispatch({ type: 'SET_LOADING', payload: true });
    let categories = [];

    // Load favorites
    const storedFavorites = typeof window !== 'undefined' ? localStorage?.getItem('favorites') : null;
    if (storedFavorites && !state.isFavoritesLoaded) {
      const favoritesParsed = JSON.parse(storedFavorites);

      if (favoritesParsed.length > 0) {
        const data = await getDatGames(favoritesParsed, 'home');
        const favoriteGames = data.gameData;

        categories.push({
          headline: 'Favoritos',
          items: favoriteGames,
          url: '/favoritos',
          icon: <IoHeart />,
        });
      }
    }

    // Load categories
    if (state.categories.length === 0) {
      const data = await getDatGames([], 'home');
      const setedCategory = CategoriesGames(data, IconCategory, 'home');
      categories = [...categories, ...setedCategory];
      dispatch({
        type: 'SET_CATEGORIES',
        payload: categories,
      });
    }

    dispatch({ type: 'SET_FAVORITES_LOADED', payload: true });
    dispatch({ type: 'SET_LOADING', payload: false });
  }, [state.categories, state.isFavoritesLoaded]);

  // Load games
  useEffect(() => {
    if (!state.isFavoritesLoaded) {
      loadData();
    }
    if ((window as any).xtremepush) {
      if (!isxtremepushSet) {
        (window as any).xtremepush('event', 'pageview_home');
        setXtremepush(true);
      }
    }
  }, [state.isFavoritesLoaded, loadData, isxtremepushSet, setXtremepush]);

  useEffect(() => {
    const initAltenar = async () => {
      const initConfig = {
        integration,
        culture: 'es-ES',
        themeName: 'dark',
      };
      (window as any).altenarWSDK?.init(initConfig);

      (widgetRef as any).current = (window as any).altenarWSDK.addWidget({
        widget: 'WTopEventsCarousel',
        props: {
          onEventSelect: (event: any) => {
            window.location.href = `/deportes?event=${event.id}`;
          },
          onOddSelect: (odd: any) => {
            window.location.href = `/deportes?event=${odd.eventId}`;
          },
          backgroundOverride: {
            sport: {
              66: 'https://cdn.sanity.io/images/mavwf57a/production/665a074ef27997f80d76ac4f3f2d2118fbad7efc-400x141.png',
              67: 'https://cdn.sanity.io/images/mavwf57a/production/0cb2696d8a088415ee2cdb14e152e9247db47b77-1792x1024.webp',
              68: 'https://cdn.sanity.io/images/mavwf57a/production/8c8e1b0d1e029261a620e0890aee4897eeb87ba2-1792x1024.webp',
              76: 'https://cdn.sanity.io/images/mavwf57a/production/2b74598240c46346d5717df0446ad3a50a3fd75b-1792x1024.webp',
            },
            championship: {
              2936: 'https://cdn.sanity.io/images/mavwf57a/production/5a5b7177a6a5351e49f8247395e92be6f6d91b7a-400x141.png',
              2941: 'https://cdn.sanity.io/images/mavwf57a/production/9554bdda86eeadf7e69113191546c01fe53b3954-400x141.png',
              2942: 'https://cdn.sanity.io/images/mavwf57a/production/0ad213d3f09f1d47364f9034ceab83e38a6266f1-400x141.png',
              16808:
                  'https://cdn.sanity.io/images/mavwf57a/production/052d85e593399ca302da16156cf0df13eed2af99-400x141.png',
              3709: 'https://cdn.sanity.io/images/mavwf57a/production/10497278e15253bc8f7b139d92acd5c27a8e1070-400x141.png',
              3685: 'https://cdn.sanity.io/images/mavwf57a/production/b0ad17f6532a9445022289c9e822433cb7006b3a-400x141.png',
              17135:
                  'https://cdn.sanity.io/images/mavwf57a/production/14305ec6ee0f818e5a7079a3620891dbc0dba422-400x141.png',
              32729:
                  'https://cdn.sanity.io/images/mavwf57a/production/14305ec6ee0f818e5a7079a3620891dbc0dba422-400x141.png',
            },
          },
          scrollerControls: 3,
          showScorePreview: true,
          variants: {
            BannerEventBox: 4,
          },
        },
        tokens: {
          OddBox: {
            borderWidth: 1,
            borderColor: '#93346a00',
            background: '#0164abba',
            oddColor: 'white',
            labelColor: 'white',
          },
          OddBoxSelected: {
            background: '#109fe9c7',
            oddColor: 'white',
            labelColor: 'white',
          },
        },
        container: document.getElementById('topscores'),
      });
    };
    if (isScriptLoaded) {
      initAltenar();
    }

    return () => {
      if ((widgetRef as any).current) {
        (widgetRef as any).current.remove();
      }
    };
  }, [integration, isScriptLoaded]);

  const searchParams = useSearchParams();
  const emailVerification = searchParams.get('emailVerified');
  const id = searchParams.get('id');
  const TxnId = searchParams.get('clientTransactionId');
  const pasarela = searchParams.get('pasarela');
  const deposit = searchParams.get('deposit');

  useEffect(() => {
    const checkConfirmation = async () => {
      if (id && TxnId) {
        dispatch({ type: 'SET_LOADING', payload: true });
        const data = {
          id: Number(id),
          clientTxId: TxnId,
        };
        try {
          const response = await fetchConfirmationPayphone(data);
          if (response.result.transaction) {
            dispatch({ type: 'SET_LOADING', payload: false });
            toast({
              title: 'Depósito Creado',
              description: 'El depósito se ha creado exitosamente',
              variant: 'default',
              className: 'bg-sivarbet-secondary',
            });
          } else {
            dispatch({ type: 'SET_LOADING', payload: false });
            toast({
              title: 'Error',
              description: 'Error al confirmar el depósito.',
              variant: 'destructive',
            });
          }
        } catch (error) {
          dispatch({ type: 'SET_LOADING', payload: false });
          toast({
            title: 'Error',
            description: 'Error al confirmar el depósito.',
            variant: 'destructive',
          });
        } finally {
          const url = new URL(window.location.href);
          url.search = '';
          window.history.replaceState({}, '', url.toString());
        }
      }
    };
    if (pasarela === 'payphone' && deposit === 'complete') {
      checkConfirmation();
    } else if (pasarela === 'payphone' && deposit === 'cancel') {
      toast({
        title: 'Depósito cancelado',
        description: 'El depósito se ha cancelado',
        variant: 'destructive',
      });
      const url = new URL(window.location.href);
      url.search = '';
      window.history.replaceState({}, '', url.toString());
    }

    if (pasarela === 'directa') {
      // TODO: check confirmation for directa
    }
  }, [id, TxnId, toast, pasarela, deposit]);

  return (
    <>
      <Script
        src={`${scriptUrl}/altenarWSDK.js`}
        strategy="afterInteractive"
        onReady={() => {
          setIsScriptLoaded(true);
        }}
      />
      {state.isLoading && <LoadingSpinner />}
      <div
        className="text-sivarbet-text flex flex-col items-stretch w-full"
        id="mainTab"
        role="tabpanel"
        tabIndex={0}
      >
        <BannerCarousel isSession={status === AUTHENTICATED_SESSION} />
        <div className="mt-3">
          <Link href="/deportes" passHref className="flex items-center gap-2">
            <span className="icon icon-calendar text-l" />
            <h2 className="text-base sm:text-sm md:text-lg lg:text-xl">
              Eventos Populares
            </h2>
          </Link>
          <div id="topscores" className="mt-6 max-lg:mt-1" />
        </div>
        <div className="flex flex-col items-stretch mt-5 max-lg:mt-2 max-md:mt-2">
          <LeagueSlider />
        </div>
        <div className="flex flex-col items-stretch w-full pt-5 max-lg:pt-0 max-md:pt-0">
          <SWSmoothCarousel data={state.categories} />
        </div>
      </div>
      {emailVerification && (
        <EmailVerificationModal param={emailVerification} session={session} />
      )}
      {/* <PopUpRegister /> */}
    </>
  );
};

export default memo(HomeTab);
